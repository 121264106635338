export const getIsLoading = ( state ) => {
    return state.isLoading;
}

export const getColumns = ( state ) => {
    return state.tableColumns;
}

export const getAllProfiles = ( state ) => {
    return state.allProfiles;
}

export const getActiveProfiles = ( state ) => {
    return state.activeProfiles;
}

export const getProfiles = ( state ) => {
    return state.profiles;
}

export const getPossibleTransitions = ( state ) => {
    return state.possibleTransitions;
}
