import { createStore } from 'vuex';
import users from "@/modules/users/store";
import profiles from "@/modules/profiles/store";

const store = createStore({
    modules: {
        profiles,
        users
    }
})

export default store;
