import { createRouter, createWebHistory } from 'vue-router';
import usersRouter from '@/modules/users/router';
import profilesRouter from '@/modules/profiles/router';

const Home = () => import('@/views/HomeView.vue');

const routes = [
    {
        path: '/',
        name: 'index',
        component: Home,
        meta: {
            in_menu: true,
            icon: 'fas fa-home',
            requiresAuth: true
        }
    },
    ...usersRouter,
    ...profilesRouter
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: 'active',
})

router.beforeEach(( to, from, next ) => {
    if ( to.matched.some(( record ) => record.meta.requiresAuth )) {
        const token = localStorage.getItem( 'token' );
        const tokenQuery = to.query.token;

        if ( !token && !tokenQuery ) {
            // TODO: in case it is not authenticated
            // next({
            //     path: '/login',
            //     query: {
            //         redirect: to.fullPath
            //     }
            // });
            next();
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
