export default () => ({
    user: [],
    users: [],
    isLoading: false,
    possibleTransitions: {
        active: {
            key: 'ACT',
            name: 'ACTIVE'
        },
        inactive: {
            key: 'INA',
            name: 'INACTIVE'
        }
    },
    tableColumns: [
        {
            label: 'Username',
            field: 'name',
            type: 'string'
        },
        {
            label: 'Email',
            field: 'email',
            type: 'string'
        },
        {
            label: 'Status',
            field: 'user_state.name',
            type: 'string'
        },
        {
            label: 'Profiles',
            field: 'profiles',
            type: 'string'
        },
        // TODO: actualizar cuando este terminada VF2-660
        // {
        //     label: 'Locations',
        //     field: 'locations',
        //     type: 'string'
        // },
        {
            label: '',
            field: 'buttons',
            sortable: false
        }
    ]
});
