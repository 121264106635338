<template>
    <label v-if="titleElement"
           :class="titleClass"
           :for="id"
    >
        {{ titleElement }}
        <span v-if="isRequired"
              class="text-primary"
        >
            *
        </span>
    </label>

    <p class="mb-0 fst-italic">
        <small v-if="textSmall">{{ textSmall }}</small>
    </p>

    <VueDatePicker
        v-model="model"
        :format="formatDate"
        :model-type="formatDate"
        :enable-time-picker="enableTimePicker"
        :max-date="maxDate"
        :placeholder="placeholder"
        :auto-apply="autoApply"
        :data-automation="`date-picker-${ id }`"
    />
</template>
<script>
import { computed } from 'vue';
import { formProps } from '@/mixins';

export default {
    name: 'VF-date-picker',
    props: {
        autoApply: {
            type: Boolean,
            required: false,
            default: false
        },
        enableTimePicker: {
            type: Boolean,
            required: false,
            default: false
        },
        formatDate: {
            type: String,
            required: false,
            default: 'yyyy-MM-dd'
        },
        maxDate: {
            type: Date,
            required: false,
            default: new Date()
        }
    },
    mixins: [
        formProps
    ],
    setup( props, { emit }) {
        const model = computed({
            get() {
                return props.modelValue;
            },
            set( val ) {
                emit('update:modelValue', val);
            }
        });

        return {
            model
        };
    }
}
</script>
