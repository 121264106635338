<template>
    <div
        v-if="isVisible"
        :id="modalId"
        class="modal-backdrop"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h1
                        v-if="modalTitle"
                        :id="modalId"
                        class="modal-title fs-5"
                    >
                        {{ modalTitle }}
                    </h1>

                    <button type="button" @click="closeModal" class="close-btn">×</button>
                </div>

                <div class="modal-body">
                    <slot name="body"/>
                </div>

                <div class="modal-footer">
                    <slot name="footer"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VF-modal',
    props: {
        isVisible: {
            type: Boolean,
            required: false,
            default: false
        },
        modalId: {
            type: String,
            required: true
        },
        modalTitle: {
            type: String,
            required: false,
            default: ''
        }
    },
    emits: [
        'close'
    ],
    setup( _, { emit }) {
        return {
            closeModal: () => emit('close')
        }
    }
}
</script>

<style scoped>
.modal-backdrop {
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
}

.close-btn {
    border: none;
    background: transparent;
    font-size: 1.5rem;
    cursor: pointer;
}

.close-btn:hover {
    box-shadow: none;
}
</style>
