/**
 * Determines whether the given variable is empty.
 *
 * @param {*} variable - The variable to check if it is empty.
 * @returns {boolean} - True if the variable is empty, false otherwise.
 */
export const is_empty_var = ( variable ) => variable === '';

/**
 * Determines if the variable length exceeds the given length.
 *
 * @param {number} variableLength - The length of the variable to be checked.
 * @param {number} length - The threshold length to compare against.
 * @returns {boolean} - True if variable length exceeds the given length, false otherwise.
 */
export const max_length_var = ( variableLength, length ) => variableLength > length;

/**
 * Determines if the length of a variable is greater than or equal to the given length.
 *
 * @param {number} variableLength - The length of the variable to compare.
 * @param {number} length - The length to compare against.
 * @returns {boolean} True if the variable length is greater than or equal to the given length, otherwise false.
 */
export const max_equals_length_var = ( variableLength, length ) => variableLength >= length;

/**
 * Determines if the length of a variable is less than the specified length.
 *
 * @param {number} variableLength - The length of the variable.
 * @param {number} length - The desired length.
 * @returns {boolean} True if the length of the variable is less than the specified length, false otherwise.
 */
export const min_length_var = ( variableLength, length ) => variableLength < length;

/**
 * Determines if the length of a given variable is less than or equal to a specified length.
 *
 * @param {number} variableLength - The length of the variable.
 * @param {number} length - The specified length to compare against.
 * @returns {boolean} - True if length of the variable is less than or equal to the specified length, otherwise false.
 */
export const min_equals_length_var = ( variableLength, length ) => variableLength <= length;

/**
 * Determines whether the given variable value is greater than the specified value.
 *
 * @param {number} variableValue - The value of the variable to be compared.
 * @param {number} value - The value to compare against.
 * @returns {boolean} - True if variableValue is greater than value, false otherwise.
 */
export const max_value_var = ( variableValue, value ) => variableValue > value;

/**
 * Checks if the given variable value is equal to or greater than the specified value.
 *
 * @param {number} variableValue - The value of the variable to be compared.
 * @param {number} value - The value to compare against the variable value.
 * @returns {boolean} - `true` if the variable value is equal to or greater than the specified value,
 *                     `false` otherwise.
 */
export const max_equals_value_var = ( variableValue, value ) => variableValue >= value;

/**
 * Checks if the value of a given variable is less than a specified value.
 *
 * @param {number|string} variableValue - The value of the variable to compare.
 * @param {number|string} value - The value to compare against.
 * @returns {boolean} - Returns true if the variable value is less than the specified value, otherwise returns false.
 */
export const min_value_var = ( variableValue, value ) => variableValue < value;

/**
 * Checks if the given variable value is less than or equal to the specified value.
 *
 * @param {number|string} variableValue - The value of the variable to compare.
 * @param {number|string} value - The value to compare the variable value against.
 * @returns {boolean} - true if the variable value is less than or equal to the specified value, false otherwise.
 */
export const min_equals_value_var = ( variableValue, value ) => variableValue <= value;

/**
 * Checks if there are any errors in the given object.
 *
 * @param {Object} errors - The object to check for errors.
 * @returns {boolean} - A boolean value indicating if there are any errors.
 */
export const have_errors = ( errors ) => Object.keys( errors ).length > 0;

/**
 * Checks if there are any errors for a given step.
 *
 * @param {Object} errors - The object containing errors for each step.
 * @param {number} step - The current step to check for errors.
 * @returns {boolean} - True if there are errors
 */
export const have_errors_step = ( errors, step ) => {
    const stepErrors = errors[ step ];
    return Object.keys( stepErrors ).some( key => stepErrors[ key ] !== '' );
}

/**
 * Retrieves a filter from an array based on a specified field.
 *
 * @param {Array} array - The input array.
 * @param {string} field - The field in each item of the array to retrieve.
 * @returns {string} - The filter string obtained by joining the fields with commas.
 */
export const get_filter_from_array = ( array, field ) => array.map( item => item[ field ]).join(',');

/**
 * Determines whether the given variable only contains letters, numbers, dashes, and underscores.
 *
 * @param {string} variable - The variable to check.
 * @returns {boolean} - True if the variable only contains letters, numbers, dashes, and underscores, false otherwise.
 */
export const is_valid_alphanumeric_var = (variable) => {
    const regex = /^[a-zA-Z0-9-_]+$/;
    return regex.test(variable);
};

/**
 * Takes a filters object, a key, and a field name and returns the corresponding filter value.
 *
 * @param {Object} filters - The filters object.
 * @param {string} key - The key to retrieve the filter value.
 * @param {string} field - The optional field name to filter.
 * @returns {string} - The filter value.
 */
export const processFilter = ( filters, key, field = '' ) => {
    const value = filters[ key ];

    if ( !value ) {
        return '';
    }

    if ( Array.isArray( value )) {
        if ( !value.length > 0 && !field ) {
            return '';
        }

        return get_filter_from_array( value, field );
    }

    return `%${ value }%`;
}
