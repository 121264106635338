const buttonsProps = {
    buttonBackImageDirection: {
        type: String,
        required: false,
        default: 'left'
    },
    buttonBackImage: {
        type: String,
        required: false,
        default: 'fas fa-arrow-left'
    },
    buttonBackText: {
        type: String,
        required: true
    },
    buttonDoneImageDirection: {
        type: String,
        required: false,
        default: 'left'
    },
    buttonDoneImage: {
        type: String,
        required: false,
        default: 'fas fa-floppy-disk'
    },
    buttonDoneText: {
        type: String,
        required: true
    },
    buttonNextImageDirection: {
        type: String,
        required: false,
        default: 'right'
    },
    buttonNextImage: {
        type: String,
        required: false,
        default: 'fas fa-arrow-right'
    },
    buttonNextText: {
        type: String,
        required: true
    },
    isCurrentStepValid: {
        type: Boolean,
        required: true
    },
    loading: {
        type: Boolean,
        required: true
    },
    showBackButton: {
        type: Boolean,
        required: true
    },
    showDoneButton: {
        type: Boolean,
        required: true
    },
    showNextButton: {
        type: Boolean,
        required: true
    },
    showCancelButton: {
        type: Boolean,
        required: true
    }
};

export default buttonsProps;
