<template>
    <div>
        <div class="steps"
             id="steps"
        >
            <VFStepperProgressBar
                :primary-color="primaryColor"
                :steps-progress-width="stepsProgressWidth"
            />

            <VFStepperItem
                v-for="(item, index) in tabs"
                :key="index"
                :step-number="index + 1"
                :title="item.title"
                :is-current="step === index + 1"
                :is-success="step > index + 1"
                :icon-success="item.iconSuccess"
                :primary-color="primaryColor"
                :styles="stepsItemCounterStyles[ index ]"
                :title-styles="stepsTitleStyles[ index ]"
            />
        </div>

        <VFStepperContent
            v-for="index in tabs.length"
            :key="index"
            :is-current-step="step === index"
        >
            <slot :name="`step${ index }`"></slot>
        </VFStepperContent>

        <VFStepperButtons
            :loading="loading"
            :is-current-step-valid="tabs[ step - 1 ].isValid"
            :show-back-button="step !== 1 && haveBackButton"
            :button-back-text="buttonBackText"
            :show-next-button="step !== tabs.length"
            :button-next-text="buttonNextText"
            :show-done-button="step === tabs.length"
            :button-done-text="buttonDoneText"
            :show-cancel-button="showCancelButton"
            @back="onDecrementStep"
            @next="buttonNextFunction"
            @done="buttonDoneFunction"
        />
    </div>
</template>

<script>
import { useVFStepper } from '@/composables';
import {
    VFStepperButtons,
    VFStepperContent,
    VFStepperItem,
    VFStepperProgressBar
} from './components';
import { stepperProps } from './mixins';

export default {
    name: 'VF-stepper',
    components: {
        VFStepperButtons,
        VFStepperContent,
        VFStepperItem,
        VFStepperProgressBar
    },
    props: {
        ...stepperProps
    },
    emits: [
        'update:step'
    ],
    setup ( props, { emit }) {
        const {
            stepsItemCounterStyles,
            stepsProgressWidth,
            stepsTitleStyles,
            onDecrementStep
        } = useVFStepper( props, emit );

        return {
            stepsItemCounterStyles,
            stepsProgressWidth,
            stepsTitleStyles,
            onDecrementStep
        };
    }
}
</script>

<style>
.steps {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: auto;
    position: relative;
    z-index: 0;
    margin-bottom: 5px;
}
</style>
