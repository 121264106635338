const itemProps = {
    stepNumber: {
        type: Number,
        required: true
    },
    title: {
        type: String,
        required: true
    },
    isCurrent: {
        type: Boolean,
        default: true
    },
    isSuccess: {
        type: Boolean,
        default: true
    },
    iconSuccess: {
        type: String,
        required: true
    },
    primaryColor: {
        type: String,
        required: true
    },
    styles: {
        type: Object,
        required: true
    },
    titleStyles: {
        type: Object,
        required: true
    }
};

export default itemProps;
