<template>
    <div class="step-item"
         :class="{
            current: isCurrent,
            success: isSuccess
         }"
    >
        <div class="step-item-counter"
             :style="styles"
        >
            <img v-if="iconSuccess"
                 class="step-mark"
                 :src="iconSuccess"
                 alt="Check Mark"
            />

            <VFStepperCheckMark
                v-else
                class="step-mark"
                :color="primaryColor"
            />

            <span class="step-number">
                {{ stepNumber }}
            </span>
        </div>

        <span class="step-title"
              :style="titleStyles"
        >
            {{ title }}
        </span>
    </div>
</template>

<script>
import VFStepperCheckMark from './VF-check-mark.vue';
import itemProps from '@/components/stepper/mixins/itemProps'

export default {
    name: 'vf-stepper-item',
    components: {
        VFStepperCheckMark
    },
    props: {
        ...itemProps
    }
};
</script>

<style scoped>
.step-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #c5c5c5;
    transition: all 500ms ease;
}

.step-item .step-item-counter {
    height: 40px;
    width: 40px;
    display: grid;
    place-items: center;
    border-radius: 100%;
    border: 2px solid #c5c5c5;
    position: relative;
}

.step-item.current .step-item-counter {
    color: var(--bs-white);
    font-weight: 600;
}

.step-item.success .step-item-counter {
    font-weight: 600;
    height: 40px;
    width: 40px;
}

.step-item .step-item-counter .step-mark {
    position: absolute;
    opacity: 0;
    transform: scale(0);
    width: 24px;
    transition: all 500ms ease;
}

.step-item.success .step-item-counter .step-mark {
    opacity: 1;
    transform: scale(1);
    width: calc(40px * 0.35);
}

.step-item .step-item-counter .step-number {
    font-size: 22px;
    transition: all 500ms ease;
}

.step-item.success .step-item-counter .step-number {
    opacity: 0;
    transform: scale(0);
    font-size: calc(40px * 0.32);
}

.step-item .step-title {
    position: absolute;
    text-align: center;
    font-size: 14px;
    bottom: -25px;
}

.step-item.current .step-title {
    color: #818181;
}
</style>
