import { createI18n } from "vue-i18n";

/**
 * TODO: tomar idioma de la redirección
 */
const internationalization = createI18n({
    locale: 'en-US',
    fallbackLocale: 'es-AR',
    legacy: false,
    messages: {
        'en-US': require('@/locales/en-US.json'),
        'es-AR': require('@/locales/es-AR.json')
    }
});

export default internationalization;
