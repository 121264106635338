export const setLoading = ( state, value ) => {
    state.isLoading = value;
}

export const setAllProfiles = ( state, value ) => {
    state.allProfiles = value;
}

export const setActiveProfiles = ( state, value ) => {
    state.activeProfiles = value;
}

export const setProfiles = ( state, value ) => {
    state.profiles = value;
}
