<template>
    <svg viewBox="0 0 24 24"
         :style="{ fill: color }"
    >
        <path
            d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"
        />
    </svg>
</template>

<script>
export default {
    name: 'vf-stepper-check-mark',
    props: {
        color: {
            type: String,
            default: 'black',
        }
    }
}
</script>
