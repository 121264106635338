import { ref, computed } from 'vue';

export default function useVFInputPassword( props ) {
    const icon = ref(props.icon);
    const isPasswordVisible = ref(false);

    const onGetInputType = computed( () => isPasswordVisible.value ? 'text' : 'password' );
    const onGetPasswordIcon = computed( () => isPasswordVisible.value ? 'fas fa-eye-slash' : icon.value );

    const onTogglePasswordVisibility = () => {
        isPasswordVisible.value = !isPasswordVisible.value;
    };

    return {
        isPasswordVisible,
        onGetInputType,
        onGetPasswordIcon,
        onTogglePasswordVisibility
    };
}
