import axios from "axios";
import cfg from "@/service/Configurations";

await cfg.initialize();

/**
 * en el header de cada peticion tiene que ir el tenant-id
 */
const authorizationApi = axios.create({
    baseURL: cfg.getConfiguration('UI_APP_BACKEND_URL')
});

/**
 * TODO: tomar token - tenant-id de la redirección
 */
authorizationApi.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    const tenant_id = localStorage.getItem('tenant-id');
    if ( token ) {
        config.headers.Authorization = `Bearer ${ token }`;
    }
    if ( tenant_id ) {
        config.headers['tenant-id'] = tenant_id;
    }
    return config;
});

export default authorizationApi;
