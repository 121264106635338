import { computed, ref } from 'vue';

export default function useVFStepper ( props, emit ) {
    const currentStep = ref(1);
    const steps = ref([]);

    const stepsProgressWidth = computed(() => {
        return ( 100 / ( props.tabs.length - 1 )) * ( props.step - 1 ) + '%';
    });

    const stepsItemCounterStyles = computed(() => {
        return props.tabs.map(( _, index ) => {
            const isCurrentStep = props.step === index + 1;
            const isSuccessStep = props.step > index + 1;

            let styles = { backgroundColor: props.secondaryColor };

            if ( isCurrentStep ) {
                styles.backgroundColor = props.primaryColor;
                styles.borderColor = props.primaryColor;
            } else if ( isSuccessStep ) {
                styles.borderColor = props.primaryColor;
            }

            return styles;
        });
    });

    const stepsTitleStyles = computed(() => {
        return props.tabs.map(( _, index ) => {
            const isSuccessStep = props.step > index + 1;

            return {
                color: isSuccessStep ? props.primaryColor : '',
                fontWeight: isSuccessStep ? 700 : 'inherit'
            }
        });
    });

    const onDecrementStep = () => {
        if ( props.step > 1 ) {
            emit( 'update:step', props.step - 1 );
        }
    };

    const onIncrementStep = ( steps, emit ) => {
        if ( currentStep.value < steps.length ) {
            currentStep.value += 1;
            emit( 'update:step', currentStep.value );
        }
    };

    return {
        currentStep,
        steps,
        stepsItemCounterStyles,
        stepsProgressWidth,
        stepsTitleStyles,
        onDecrementStep,
        onIncrementStep,
        setSteps: ( newSteps ) => steps.value = newSteps
    }
}
