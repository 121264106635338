export const getIsLoading = ( state ) => {
    return state.isLoading;
}

export const getColumns = ( state ) => {
    return state.tableColumns;
}

export const getUsers = ( state ) => {
    return state.users;
}

export const getUser = ( state ) => {
    return state.user;
}

export const getPossibleTransitions = ( state ) => {
    return state.possibleTransitions;
}
