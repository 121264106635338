const List = () => import(
    /* webpackChunkName: "ProfilesListView" */
    '@/modules/profiles/views/ListView.vue'
);
const ManageData = () => import(
    /* webpackChunkName: "ProfilesManageData" */
    '@/modules/profiles/views/ManageData.vue'
);
const ViewData = () => import(
    /* webpackChunkName: "ProfilesViewData" */
    '@/modules/profiles/views/ViewData.vue'
);

export default [
    {
        path: '/profiles',
        name: 'profiles',
        component: List,
        meta: {
            in_menu: true,
            public: false,
            icon: 'fa-solid fa-gear',
            requiresAuth: true
        }
    },
    {
        path: '/profiles/create',
        name: 'profile_create',
        component: ManageData,
        meta: {
            public: false,
            requiresAuth: true
        }
    },
    {
        path: '/profiles/view/:profile_id',
        name: 'profile_view',
        component: ViewData,
        meta: {
            public: false,
            requiresAuth: true
        }
    },
    {
        path: '/profiles/edit/:profile_id',
        name: 'profile_edit',
        component: ManageData,
        meta: {
            public: false,
            requiresAuth: true
        }
    }
]

