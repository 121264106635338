const List = () => import(
    /* webpackChunkName: "UsersListView" */
    '@/modules/users/views/ListView.vue'
);
const ManageData = () => import(
    /* webpackChunkName: "UsersManageData" */
    '@/modules/users/views/ManageData.vue'
);
const ViewData = () => import(
    /* webpackChunkName: "UsersViewData" */
    '@/modules/users/views/ViewData.vue'
);

export default [
    {
        path: '/users',
        name: 'users',
        component: List,
        meta: {
            in_menu: true,
            public: false,
            icon: 'fa-solid fa-user',
            requiresAuth: true
        }
    },
    {
        path: '/users/create',
        name: 'user_create',
        component: ManageData,
        meta: {
            public: false,
            requiresAuth: true
        }
    },
    {
        path: '/users/view/:user_id',
        name: 'user_view',
        component: ViewData,
        meta: {
            public: false,
            requiresAuth: true
        }
    },
    {
        path: '/users/edit/:user_id',
        name: 'user_edit',
        component: ManageData,
        meta: {
            public: false,
            requiresAuth: true
        }
    }
]

