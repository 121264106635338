<template>
    <div class="step-progress">
        <div
            class="step-progress-bar"
            :style="{
                width: stepsProgressWidth,
                backgroundColor: primaryColor
            }"
        ></div>
    </div>
</template>
<script>
export default {
    name: 'vf-stepper-progress-bar',
    props: {
        stepsProgressWidth: {
            type: String,
            required: true
        },
        primaryColor: {
            type: String,
            required: true
        },
    }
}
</script>

<style>
.step-progress {
    position: absolute;
    background-color: #c5c5c5;
    height: 2px;
    z-index: -1;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.step-progress-bar {
    position: absolute;
    left: 0;
    height: 100%;
    width: 0;
    transition: all 500ms ease;
}
</style>
