const stepperProps = {
    buttonBackText: {
        type: String,
        required: true
    },
    buttonDoneFunction: {
        type: Function,
        required: true
    },
    buttonDoneText: {
        type: String,
        required: true
    },
    buttonNextFunction: {
        type: Function,
        required: true
    },
    buttonNextText: {
        type: String,
        required: true
    },
    haveBackButton: {
        type: Boolean,
        required: false,
        default: true
    },
    loading: {
        type: Boolean,
        required: true
    },
    primaryColor: {
        type: String,
        required: false,
        default: 'var(--vf-primary)'
    },
    secondaryColor: {
        type: String,
        required: false,
        default: 'var(--vf-gratish-cyan)'
    },
    showCancelButton: {
        type: Boolean,
        required: false,
        default: true
    },
    step: {
        type: Number,
        required: true
    },
    tabs: {
        type: Array,
        required: true
    },
};

export default stepperProps;
