export default () => ({
    allProfiles: [],
    activeProfiles: [],
    profile: [],
    profiles: [],
    isLoading: false,
    possibleTransitions: {
        active: {
            key: 1,
            name: 'ACTIVE'
        },
        inactive: {
            key: 0,
            name: 'INACTIVE'
        }
    },
    tableColumns: [
        {
            label: 'Name',
            field: 'name',
            type: 'string'
        },
        {
            label: 'Description',
            field: 'description',
            type: 'string'
        },
        {
            label: 'Status',
            field: 'active',
            type: 'string'
        },
        {
            label: '',
            field: 'buttons',
            sortable: false
        }

    ]
});
