import { authorizationApi } from '@/api';
import {
    getConnectionsMessageError,
    processFilter
} from '@/helpers';

/**
 *  Create a user
 */
export const createUser = async ({ commit }, { t, store, data: dataForm }) => {
    try {
        commit( 'setLoading', true );

        const {
            username,
            email,
            password,
            first_name,
            last_name,
            profiles
        } = dataForm.value;

        const params = {
            user: {
                name: username,
                email,
                active: true,
                password,
                employee: {
                    first_name,
                    last_name
                }
            }
        };

        const { data } = await authorizationApi.post(
            '/api/who/users',
            params
        );

        await assignProfiles( t, store, data.id, profiles );

        commit( 'setLoading', false );

        return {
            ok: true,
            message: t('users.api.create_ok')
        }
    } catch ( error ) {
        const message = getConnectionsMessageError(
            error,
            t('users.api.create_error')
        );

        commit('setLoading', false);

        return {
            ok: false,
            message
        }
    }
}

/**
 * Assign profile/s to a user
 */
export const assignProfiles = async ( t, store, userId, profiles ) => {
    const profile_ids = profiles.map( profile => profile.id );

    await store.dispatch(
        'profiles/assignProfiles',
        { t, userId, profile_ids }
    );
}

/**
 *  Get all users
 */
export const getUsers = async ({ commit }, { t, filters = {} }) => {
    try {
        commit( 'setLoading', true );

        const relations = 'profiles,user_state,employee.employee_state,workflow_history.user,possible_transitions';

        const filterParams = Object.keys( filters ).length
            ? getFilterParams(filters)
            : '';

        const { data } = await authorizationApi.get(
            `/api/who/users?relations=${ relations }${ filterParams }`
        );

        commit( 'setUsers', data );
        commit( 'setLoading', false );

        return {
            ok: true
        }
    } catch ( error ) {
        const message = getConnectionsMessageError(
            error,
            t('users.api.get_all_error')
        );

        commit('setLoading', false);

        return {
            ok: false,
            message
        }
    }
}


/**
 *  Update a user state
 */
export const updateUserState = async ({ commit }, { t, user, stateTo }) => {
    try {
        commit('setLoading', true);

        const params = {
            transition: {
                user_state_code: stateTo
            }
        };

        await authorizationApi.patch(
            `/api/who/users/${ user.id }`,
            params
        );

        commit('setLoading', false);

        return {
            ok: true,
            message: t('users.api.update_state_ok')
        }
    } catch ( error ) {
        commit('setLoading', false);

        return {
            ok: false,
            message: t('users.api.update_state_error')
        }
    }
}


const getFilterParams = ( filters ) => {
    const processedFilters = {
        'name[lk]': processFilter( filters, 'username' ),
        'email[lk]': processFilter( filters, 'email' ),
        'user_profiles[in]': processFilter( filters, 'profiles', 'name' ),
        'user_state_code[in]': processFilter( filters, 'states', 'id' ),
        'created_at': filters.entryDate || ''
    };

    const validFilters = Object.entries( processedFilters ).filter(
        ([ , value ]) => value !== ''
    );

    const filterParams = validFilters.map(
        ([ key, value ]) => `${ key }=${ encodeURIComponent( value )}`
    ).join('&');

    return filterParams ? `&${ filterParams }` : '';
}
