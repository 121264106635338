<template>
    <div
        v-if="isCurrentStep"
        class="step-pane"
    >
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'vf-stepper-content',
    props: {
        isCurrentStep: {
            type: Boolean,
            required: true
        }
    }
}
</script>

<style scoped>
.step-pane {
    color: #333;
    text-align: center;
    background-color: transparent;
    border-radius: 15px;
    padding: 25px 25px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    margin: 60px 0 20px 0;
}
</style>
