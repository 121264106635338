import { ref, computed } from 'vue';
import { Tooltip } from 'bootstrap';

export default function useVFButton(props, emit) {
    const size = ref(props.size);
    const vfClass = ref(props.vfClass);
    const icon = ref(props.icon);
    const iconDir = ref(props.iconDir);
    const iconColor = ref(props.iconColor);

    const onGetClasses = computed(() => {
        let buttonClass = vfClass.value ?  `vf-${vfClass.value}` : '';
        let sizeValue = size.value ? `btn-${size.value}` : '';
        return `${buttonClass} ${sizeValue}`;
    });

    const onGetIcon = computed(
        () => (icon.value && iconDir.value)
            ? `${icon.value} float-${iconDir.value} ${iconColor.value}`
            : ''
        );

    const onInitTooltips = () => {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        [...tooltipTriggerList].forEach(tooltipTriggerEl => new Tooltip(tooltipTriggerEl));
    }

    const onHandleClick = () => {
        onHideTooltips();
        emit('action-click');
    };

    const onHideTooltips = () => {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        [...tooltipTriggerList].forEach(tooltipTriggerEl => {
            const tooltip = Tooltip.getInstance(tooltipTriggerEl);
            if (tooltip) {
                tooltip.hide();
            }
        });
    };

    return {
        icon,
        iconDir,
        size,
        vfClass,
        onGetClasses,
        onGetIcon,
        onHandleClick,
        onHideTooltips,
        onInitTooltips
    }
}
