/* eslint-disable */
const cfg = {
    initialized: false,
    getItemName() {
        return 'cfg_frontend';
    },
    async initialize() {
        if (this.initialized) {
            return;
        }

        try {
            const response = await fetch('/config.json');
            const data = await response.json();
            localStorage.setItem(this.getItemName(), JSON.stringify(data));
            this.initialized = true;
        } catch (error) {
            console.error('initialize frontend configuration error', error);
        }
    },
    getConfiguration(key) {
        const config = localStorage.getItem(this.getItemName());
        const configJson = JSON.parse(config);
        if (!configJson) {
            console.error('frontend configuration error. Config not found');
            return null;
        }
        return configJson[key];
    }
};

export default cfg;
