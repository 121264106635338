export const setLoading = ( state, value ) => {
    state.isLoading = value;
}

export const setUsers = ( state, value ) => {
    state.users = value;
}

export const setUser = ( state, value ) => {
    state.user = value;
}
