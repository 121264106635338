import { authorizationApi } from '@/api';
import { getConnectionsMessageError } from '@/helpers';


/**
 * Create a profile
 */
export const createProfile = async ({ commit }, { t, data: dataForm }) => {
    try {
        commit( 'setLoading', true );

        const {
            name,
            description
        } = dataForm.value;

        const params = {
            profile: {
                name,
                description
            }
        };

        await authorizationApi.post(
            'api/what/profiles',
            params
        );

        commit( 'setLoading', false );

        return {
            ok: true,
            message: t('profiles.api.create_ok')
        }
    } catch (error) {
        const message = getConnectionsMessageError(
            error,
            t('profiles.api.create_error')
        )

        commit('setLoading', false);

        return {
            ok: false,
            message
        }
    }
}

/**
 * Get all profiles
 */
export const getProfiles = async ({ commit }, { t, activeProfiles }) => {
    try {
        commit( 'setLoading', true );

        const filterParam = activeProfiles ? '?active=1' : '';
        const { data } = await authorizationApi.get(`/api/what/profiles${ filterParam }`);

        const profiles = data.map( profile => ({
            id: profile.id,
            name: profile.name,
            description: profile.description,
            active: profile.active
        }));

        const mutationName = activeProfiles ? 'setActiveProfiles' : 'setAllProfiles';
        commit( mutationName, profiles );
        commit( 'setProfiles', profiles );

        commit( 'setLoading', false );

        return {
            ok: true
        }
    } catch ( error ) {
        const message = getConnectionsMessageError(
            error,
            t('profiles.api.get_all_error')
        );

        commit( 'setLoading', false );

        return {
            ok: false,
            message
        }
    }
}

/**
 * Assign profile for user
 */
export const assignProfiles = async ( _, { t, userId, profile_ids }) => {
    try {
        const paramsProfile = {
            profiles: profile_ids
        };

        await authorizationApi.patch(
            `/api/who/users/${ userId }`,
            paramsProfile
        );

        return {
            ok: true
        }
    } catch ( error ) {
        const message = getConnectionsMessageError(
            error,
            t('profiles.api.assign_error')
        );

        return {
            ok: false,
            message
        }
    }
}

