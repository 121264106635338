<template>
    <div class="dropdown text-center">
        <span @click.stop="onToggleDropdownMenu( row.id, $event )"
              class="btn btn-link p-0 text-black"
              data-automation="button-dropdown-menu"
        >
            <i class="fas fa-ellipsis-v"></i>
        </span>

        <ul class="dropdown-menu position-fixed shadow"
            :style="{ top: dropdownMenuPosition.top, left: dropdownMenuPosition.left }"
            :class="{ show: isDropdownMenuOpen( row.id )}"
            :ref="el => onSetDropdownMenuContainerRef( el, row.id )"
            @click.stop
        >
            <VFDropdownItem
                icon="fas fa-eye"
                data-automation="button-dropdown-menu-view"
                :action="() => onViewFunction( row )"
                :text="$t('general.table.button_view', { property: entityName })"
            />

            <VFDropdownItem
                icon="fas fa-edit"
                data-automation="button-dropdown-menu-edit"
                :action="() => onEditFunction( row )"
                :text="$t('general.table.button_edit', { property: entityName })"
            />

            <VFDropdownItem
                v-if="entityName === $t('general.table.profile')"
                icon="fas fa-key"
                data-automation="button-dropdown-menu-edit-permissions"
                :action="() => onEditPermissionsFunction( row )"
                :text="$t('general.table.button_edit_permissions')"
            />

            <VFDropdownItem
                v-if="isDeactivateOptionEnabledFunction( elementStateName )"
                icon="fas fa-check"
                data-automation="button-dropdown-menu-active"
                :action="() => onChangeStateFunction( row, activeAcronym )"
                :text="$t('general.table.button_active', { property: entityName })"
            />

            <VFDropdownItem
                v-if="isActiveOptionEnabledFunction( elementStateName )"
                icon="fas fa-ban"
                data-automation="button-dropdown-menu-deactivate"
                :action="() => onChangeStateFunction( row, inactiveAcronym )"
                :text="$t('general.table.button_inactive', { property: entityName })"
            />
        </ul>
    </div>
</template>
<script>
import { VFDropdownItem } from '@/components';

export default {
    name: 'ListViewTableButtons',
    components: {
        VFDropdownItem

    },
    props: {
        activeAcronym: {
            type: String,
            required: true
        },
        dropdownMenuPosition: {
            type: Object,
            required: true
        },
        elementStateName: {
            type: String,
            required: true
        },
        inactiveAcronym: {
            type: String,
            required: true
        },
        isActiveOptionEnabledFunction: {
            type: Function,
            required: true
        },
        isDeactivateOptionEnabledFunction: {
            type: Function,
            required: true
        },
        isDropdownMenuOpen: {
            type: Function,
            required: true
        },
        row: {
            type: Object,
            required: true
        },
        onChangeStateFunction: {
            type: Function,
            required: true
        },
        onEditFunction: {
            type: Function,
            required: true
        },
        onSetDropdownMenuContainerRef: {
            type: Function,
            required: true
        },
        onToggleDropdownMenu: {
            type: Function,
            required: true
        },
        onViewFunction: {
            type: Function,
            required: true
        },
        entityName: {
            type: String,
            required: true
        },
        onEditPermissionsFunction: {
            type: Function,
            required: false
        }
    }
}
</script>
