<template>
    <div id="buttons">
        <VFButton
            v-if="showBackButton"
            vf-class="btn btn-secondary float-start btn-sm"
            size="sm"
            :icon-dir="buttonBackImageDirection"
            :icon="buttonBackImage"
            :loading="loading"
            :disabled="loading"
            @click="onBack"
            data-automation="button-back"
        >
            {{ buttonBackText }}
        </VFButton>

        <router-link
            v-if="showCancelButton"
            :to="{ name: 'users' }"
            class="btn btn-light border border-secondary float-start btn-sm ms-2"
            :class="loading ? 'disabled' : ''"
            style="padding-top: 0.3rem; padding-bottom: 0.3rem"
            data-automation="button-cancel">
            <i class="fas fa-times"></i>
            {{ $t('general.buttons.cancel') }}
        </router-link>

        <VFButton
            v-if="showNextButton"
            vf-class="button-primary float-end"
            size="sm"
            :icon-dir="buttonNextImageDirection"
            :icon="buttonNextImage"
            :loading="loading"
            :disabled="loading || !isCurrentStepValid"
            @click="onNext"
            data-automation="button-next"
        >
            {{ buttonNextText }}
        </VFButton>

        <VFButton
            v-if="showDoneButton"
            vf-class="button-primary float-end"
            size="sm"
            :icon-dir="buttonDoneImageDirection"
            :icon="buttonDoneImage"
            :loading="loading"
            :disabled="loading || !isCurrentStepValid"
            @click="onDone"
            data-automation="button-save"
        >
            {{ buttonDoneText }}
        </VFButton>
    </div>
</template>

<script>
import { VFButton } from '@/components';
import buttonsProps from '@/components/stepper/mixins/buttonsProps';

export default {
    name: 'vf-stepper-buttons',
    components: {
        VFButton
    },
    props: {
        ...buttonsProps
    },
    emits: [
        'back',
        'done',
        'next'
    ],
    methods: {
        onBack() {
            this.$emit('back');
        },
        onDone() {
            this.$emit('done');
        },
        onNext() {
            this.$emit('next');
        }
    }
}
</script>
