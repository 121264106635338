<template>
    <div class="badge" :class="vfClass">
        <i v-if="icon" :class="icon"></i>

        {{ textBadge }}

        <span
            v-if="showCloseIcon"
            class="ms-2 vf-success close-container"
        >
            <i
                class="fa-solid fa-times close-icon"
                @click="onClose"
            ></i>
        </span>
    </div>
</template>

<script>
    export default {
        name: "VF-badge",
        props: {
            icon: {
                type: String,
                required: false
            },
            showCloseIcon: {
                type: Boolean,
                required: false,
                default: false,
            },
            textBadge: {
                type: String,
                required: true
            },
            vfClass: {
                type: String,
                default: "vf-primary",
            }
        },
        emits: [
            'close'
        ],
        setup( _, { emit }) {
            return {
                onClose: () => emit( 'close' )
            }
        }
    };
</script>

<style scoped>
.close-icon {
    cursor: pointer;
}

.badge {
    display: inline-flex;
    align-items: center;
    padding: 5px 10px;
}

.close-container {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    padding-top: 4px;
}
</style>
