<template>
    <label v-if="titleElement"
           :class="titleClass"
           :for="id"
    >
        {{ titleElement }}
        <span v-if="isRequired"
              class="text-primary"
        >
            *
        </span>
    </label>

    <p class="mb-0 fst-italic">
        <small v-if="textSmall">{{ textSmall }}</small>
    </p>

    <MultiSelect
        v-model="internalValue"
        :options="options"
        :label="displayOption"
        :track-by="selectOptionUnique"
        :multiple="isMultiple"
        :placeholder="placeholder"
        :selectLabel="selectLabelMsg"
        :deselectLabel="deselectLabelMsg"
        :selectedLabel="selectedLabelMsg"
        :showNoOptions="true"
        :noOptions="noOptionsMsg"
        :showNoResults="true"
        :noResult="noResultsMsg"
        :data-automation="`select-${ id }`"
    />

    <span v-if="textBelow" :class="classBelow">{{ textBelow }}</span>
</template>

<script>
import { computed } from 'vue';
import { formProps } from '@/mixins';

export default {
    name: 'VF-multi-select',
    props: {
        deselectLabelMsg: {
            type: String,
            required: true,
            default: ''
        },
        displayOption: {
            type: String,
            required: true,
            default: ''
        },
        isMultiple: {
            type: Boolean,
            required: true,
            default: true
        },
        noOptionsMsg: {
            type: String,
            required: true,
            default: ''
        },
        noResultsMsg: {
            type: String,
            required: true,
            default: ''
        },
        options: {
            type: Array,
            required: true,
            default: () => []
        },
        selectLabelMsg: {
            type: String,
            required: true,
            default: ''
        },
        selectOptionUnique: {
            type: String,
            required: true,
            default: ''
        },
        selectedLabelMsg: {
            type: String,
            required: true,
            default: ''
        }
    },
    mixins: [
        formProps
    ],
    setup( props, { emit }) {
        const internalValue = computed({
            get() {
                return props.modelValue;
            },
            set( val ) {
                emit('update:modelValue', val);
            }
        });

        return {
            internalValue
        };
    }
};
</script>

<style>
.multiselect {
    border: none !important;
}

.multiselect__tags {
    border: 1px solid #ced4da !important;
    line-height: 1.5;
}

.multiselect__placeholder {
    padding: 0 !important;
}
</style>
